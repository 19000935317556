import React, { useCallback } from 'react';
import { Settings, Trash2 } from 'lucide-react';
import { Menu, Item, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { doc, writeBatch, collection, getDocs } from 'firebase/firestore';
import { firestore } from './firebase';
import './ChatSidebar.css';

const ChatSidebar = ({ 
  isOpen, 
  chats, 
  onChatSelect, 
  selectedChatId, 
  hasSubscription, 
  onUpgrade, 
  userName, 
  userEmail, 
  onSettingsClick,
  isMobile
}) => {
  const { show } = useContextMenu({
    id: 'chat-context-menu',
  });

  const filteredChats = chats.filter(chat =>
    chat.topic.toLowerCase().includes('')
  );

  const handleContextMenu = useCallback((event, chatId) => {
    event.preventDefault();
    show({
      event,
      props: {
        chatId,
      },
    });
  }, [show]);

  const deleteChat = useCallback(async ({ props }) => {
    if (!props.chatId) return;

    try {
      const batch = writeBatch(firestore);
      const chatRef = doc(firestore, 'chats', props.chatId);

      // Delete all messages in the chat
      const messagesRef = collection(firestore, 'chats', props.chatId, 'messages');
      const messagesSnapshot = await getDocs(messagesRef);
      messagesSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Delete the chat document
      batch.delete(chatRef);

      await batch.commit();
      
      // You might want to update the local state or trigger a re-fetch of chats here
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  }, [firestore]);

  return (
    <div className={`bg-[rgb(209,209,205)] dark:bg-[rgb(19,19,19)] text-black dark:text-white h-full flex flex-col overflow-hidden transition-all duration-300 ease-in-out ${isMobile ? '' : 'pt-16'}`}
         style={{ width: isOpen ? '320px' : '0px' }}>
      {isOpen && (
        <>
          <div className={`flex-grow overflow-y-auto ${isMobile ? '' : 'pt-4'}`}>
            <div className="p-4">
              {filteredChats.map(chat => (
                <div
                  key={chat.id}
                  className={`p-3 mb-2 rounded-lg cursor-pointer flex items-center ${
                    selectedChatId === chat.id
                      ? 'bg-[rgb(199,199,195)] dark:bg-[rgb(69,69,69)]'
                      : 'bg-[rgb(219,219,215)] dark:bg-[rgb(36,36,36)] hover:bg-[rgb(204,204,201)] dark:hover:bg-gray-600'
                  }`}
                  onClick={() => onChatSelect(chat.id)}
                  onContextMenu={(e) => handleContextMenu(e, chat.id)}
                >
                  <div className="flex-grow min-w-0">
                    <h3 className="font-semibold truncate">{chat.topic || 'Untitled Chat'}</h3>
                    <span className="text-xs text-gray-600 dark:text-gray-400 truncate">{chat.lastMessageTimeAgo || 'No messages yet'}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={`px-6 py-4 border-t border-[rgb(199,199,195)] dark:border-gray-900 ${isMobile ? 'mt-auto' : ''}`}>
            <div className="flex items-center">
              <div className="w-10 h-10 rounded-lg bg-purple-500 flex items-center justify-center text-white font-bold text-xl mr-3 flex-shrink-0">
                {userName.charAt(0).toUpperCase()}
              </div>
              <div className="flex-grow min-w-0">
                <h3 className="font-semibold truncate">{userName}</h3>
                <p className="text-xs text-gray-600 dark:text-gray-400 truncate">{userEmail}</p>
              </div>
              <button className="ml-2 p-1 rounded-full hover:bg-gray-300 dark:hover:bg-gray-700 flex-shrink-0" onClick={onSettingsClick}>
                <Settings size={20} />
              </button>
            </div>
            {!hasSubscription && (
              <button
                onClick={onUpgrade}
                className="mt-4 w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-4 rounded"
              >
                Upgrade to Pro
              </button>
            )}
          </div>
        </>
      )}
      <Menu id="chat-context-menu">
        <Item onClick={deleteChat}>
          <Trash2 size={18} className="mr-2" />
          Delete Chat
        </Item>
      </Menu>
    </div>
  );
};

export default ChatSidebar;